import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import LocationLayout from '../../components/locationLayout'

const Location = ({ data }) => {
    const categories = data.allPrismicLocationcategory.edges.map(({ node }) => node.data.name.text);

    return (
        <LocationLayout>
            <div className="location-home w-[1920] max-w-[100%] justify-center mx-auto">

                {/* HEADER */}
                <center>
                    <div className="top-image text-center w-full px-8">
                        <StaticImage alt='Image de la scène du Festif!' className="max-w-[1856px] max-h-[1044px]" src="../../images/location/header.png" />
                    </div>
                </center>

                {/* TEXT */}
                <div className='md:mx-96 mx-9 !text-center my-9 '>
                    <p className='font-poppins font-bold md:text-2xl text-xl italic uppercase'>
                        Offre à ton évènement une touche unique avec le matériel à louer du Festif! de Baie-Saint-Paul! 
                        Que ce soit pour un festival, une soirée privée ou un rassemblement communautaire, nos installations uniques sont là pour répondre à tes besoins.
                    </p>
                </div>

                {/* BUTTONS */}
                <div className='flex text-center w-full'>

                    <div className="mx-auto inline-grid grid-cols-3 grid-rows-2 md:gap-[30px] gap-[5px] p-[15px] md:w-[calc(100%-1.5rem)] w-[calc(100%-0.25rem)] mb-12 lg:w-[calc(100%-12rem)] max-w-[1500px] justify-center">
                        {/* Bouton "Tous les produits" */}
                        <a className="location-page-buttons location-page-buttons-double bg-black row-span-2 col-span-1 px-3" href="/location/produits?categorie=tous">
                            <div className="flex flex-col w-full">
                                <p>TOUS LES<br/>PRODUITS</p>
                            </div>
                        </a>

                        {categories.map((category, index) => (
                            <a 
                                key={index} 
                                className="location-page-buttons bg-black row-span-1 col-span-1" 
                                href={`/location/produits?categorie=${category.toLowerCase().replace(/\s+/g, '-')}`}
                            >
                                <p>{category.toUpperCase()}</p>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </LocationLayout>
    );
}

export default Location;

export const locationCategoriesQuery = graphql`
query {
    allPrismicLocationcategory {
        edges {
            node {
                data {
                    name {
                        text
                    }
                }
            }
        }
    }
}
`