import React from 'react'
import Wolflayout from './wolflayout'
import Helmet from 'react-helmet'

const CabaretLayout = ({ children }) => {
  var title = 'Le Festif! à louer'
  var description = 'Offre à ton évènement une touche unique avec le matériel à louer du Festif! de Baie-Saint-Paul.'
  return (
    <Wolflayout bodyClass={'location-body'} isLocation={true}>
        <div>
          <Helmet>
              <title>{title}</title>
              <meta name="description" content={description} />
              <meta name="keywords" content={'festival, location, matériel, équipement, musique, scène, louer'} />
              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />
              <meta property="og:url" content={'https://lefestif.ca/location'} />
              <meta property="og:type" content="website" />
              <meta property="og:image" content={'https://images.prismic.io/le-festif-ca/Z8H7Kp7c43Q3gYvf_47.png'} />
          </Helmet>
        </div>
        <main>{children}</main>
    </Wolflayout>
  )
}

export default CabaretLayout
